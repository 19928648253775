import {
  AppBar,
  Box,
  Button, IconButton, LinearProgress, Toolbar,
  Typography
} from "@material-ui/core";
import axios from "axios";
import { ethers } from "ethers";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Web3Modal from "web3modal";
import logo from "../assets/images/coinvise_logo.png";
import ERC20WithPartnerABI from "../contracts/ERC20WithPartner/ABI.json";
import ERC20WithPartnerByteCode from "../contracts/ERC20WithPartner/ByteCode.json";
import { MintTokenDto, Token } from "../interfaces/token.interface";
import { getNetworkName, getTxUrl } from "../utils/ethereumUtils";
import { web3ModalProviderOptions } from "../utils/wallet";

const MintToken: FC = () => {
  const web3Modal = new Web3Modal({
    providerOptions: web3ModalProviderOptions,
  });

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [walletProvider, setWalletProvider] =
    useState<ethers.providers.Web3Provider | undefined>(undefined);
  const [chainId, setChainId] = useState(0);
  const [tokenData, setTokenData] = useState<Token | undefined>(undefined);
  const [address, setAddress] = useState("");
  const [txHash, setTxHash] = useState("");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const tokenId = searchParams.get("tokenId");
      if (tokenId) {
        const data: Token = await axios
          .get(`${process.env.REACT_APP_CV_API}/token/${tokenId}`)
          .then((res) => res.data.data);

        console.log(data);

        setTokenData(data);
        setIsLoading(false);
      }
    })();
  }, [location.search]);

  const onConnectClick = async () => {
    try {
      const provider = await web3Modal.connect();
      const web3Local = new ethers.providers.Web3Provider(provider);
      const network = await web3Local.getNetwork();
      const signer = web3Local.getSigner();
      const addr = await signer.getAddress();

      /*
      const rawMessage = process.env.REACT_APP_SIGNING_MESSAGE_ADD_WALLET ?? "";
      const msgHash = ethers.utils.hashMessage(rawMessage);
      const msgHashBytes = ethers.utils.arrayify(msgHash);

      const signature = await signer.signMessage(msgHashBytes);

      // Login and get the cookies
      await axios.post(`${process.env.REACT_APP_CV_API}/client/signature`, {
        signature,
        address: ethers.utils.getAddress(addr),
      });
      */

      setWalletProvider(web3Local);
      setChainId(network.chainId);
      setAddress(addr);

      // provider.on("accountsChanged", (accounts: string[]) => {
      //   setAddress(accounts[0]);
      // });

      provider.on("chainChanged", () => {
        window.location.reload();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onMint = async () => {
    if (walletProvider && tokenData) {
      const signer = walletProvider.getSigner();

      const decimals = tokenData.decimals;
      const expDecimals = ethers.BigNumber.from(10).pow(decimals);
      const tokenSupply = ethers.BigNumber.from(10000000).mul(expDecimals);

      const tokenFactory = new ethers.ContractFactory(
        ERC20WithPartnerABI,
        ERC20WithPartnerByteCode,
        signer
      );

      // Deploy
      const newToken = await tokenFactory.deploy(
        tokenData.name,
        tokenData.symbol,
        ethers.utils.getAddress(address),
        // TODO: Change
        "0x8df737904ab678B99717EF553b4eFdA6E3f94589", // coinvise
        "0x5bb3e1774923b75Ecb804E2559149BbD2a39A414", // collabland
        tokenSupply
      );

      const txData = await newToken.deployTransaction.wait();
      const newTokenAddress = newToken.address;

      const postData: MintTokenDto = {
        address: newTokenAddress,
        id: tokenData.id,
        txHash: txData.transactionHash,
      };

      setTxHash(txData.transactionHash);

      try {
        await axios.post(
          `${process.env.REACT_APP_CV_API}/token/finish`,
          postData,
          { withCredentials: true }
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(walletProvider);
      console.log(tokenData)
    }
  };
  /*
  const addToWallet = async (
    address: string,
    symbol: string,
    decimals: number
  ) => {
    if (walletProvider) {
      const params = {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: "0x1e81A9F4F33a567b4E50D32a0A3CAd01E6c81d35", // The address that the token is at.
          symbol: "MIHIRR", // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
          // image: tokenImage, // A string url of the token logo
        },
      };
      console.log(params);
      try {
        // window.ethereum.request({
        //   method: "wallet_watchAsset",
        //   params: {
        //     type: "ERC20",
        //     options: {
        //       address: "0x1e81A9F4F33a567b4E50D32a0A3CAd01E6c81d35",
        //       symbol: "MIHIRR",
        //       decimals: 18,
        //       // image: "https://foo.io/token-image.svg",
        //     },
        //   },
        // });
        await walletProvider.send("wallet_watchAsset", [params]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  */
  return (
    <>
      <AppBar style={{ color: "white" }} color="transparent" position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img src={logo} alt="" style={{ height: "60px" }} />
          </IconButton>
          {address ? (
            <Box ml="auto">
              <Typography
                variant="h6"
                style={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                }}
                color="initial"
              >
                {`${address.slice(0, 4)}...${address.slice(
                  address.length - 4
                )}`}
              </Typography>
            </Box>
          ) : (
            <Button
              variant="contained"
              style={{ marginLeft: "auto" }}
              onClick={onConnectClick}
            >
              Connect Wallet
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Box color="white" mt="1rem" pr="1rem" pl="1rem">
          {(() => {
            if (tokenData) {
              if (txHash) {
                return (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    style={{ minHeight: "80vh" }}
                  >
                    <Typography
                      variant="h4"
                      color="initial"
                      align="center"
                      gutterBottom
                    >
                      Hooray! ${tokenData.symbol} was successfully minted!
                    </Typography>
                    <Button
                      variant="contained"
                      target="_blank"
                      color="secondary"
                      href={getTxUrl(tokenData.chainId, txHash)}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      View Transaction
                    </Button>
                    {/* <Button
                      variant="contained"
                      color="default"
                      onClick={() =>
                        addToWallet(
                          address,
                          tokenData.symbol,
                          tokenData.decimals
                        )
                      }
                    >
                      Add to wallet
                    </Button> */}
                  </Box>
                );
              } else if (tokenData.minted) {
                return (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    style={{ minHeight: "80vh" }}
                  >
                    <Typography variant="h4" color="initial">
                      ${tokenData.symbol} has already been minted!
                    </Typography>
                  </Box>
                );
              } else if (address) {
                if (tokenData.chainId === chainId) {
                  return (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      style={{ minHeight: "80vh" }}
                    >
                      <Typography variant="h3" color="initial">
                        Mint 10,000,000 ${tokenData.symbol}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        style={{ marginBottom: "1rem" }}
                      >
                        This will mint an ERC20 token with the name{" "}
                        <Typography
                          variant="body2"
                          component="span"
                          color="secondary"
                          style={{ fontWeight: 600 }}
                        >
                          {tokenData.name}
                        </Typography>{" "}
                        , symbol{" "}
                        <Typography
                          variant="body2"
                          component="span"
                          color="secondary"
                          style={{ fontWeight: 600 }}
                        >
                          {tokenData.symbol}
                        </Typography>{" "}
                        and{" "}
                        <Typography
                          variant="body2"
                          component="span"
                          color="secondary"
                          style={{ fontWeight: 600 }}
                        >
                          {tokenData.decimals}
                        </Typography>{" "}
                        decimals
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onMint}
                      >
                        Mint
                      </Button>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      style={{ minHeight: "80vh" }}
                    >
                      <Typography variant="h4" color="initial" gutterBottom>
                        Wrong Network
                      </Typography>
                      <Typography variant="h5" color="initial">
                        Please connect to the{" "}
                        {getNetworkName(tokenData ? tokenData.chainId : 0)}
                      </Typography>
                    </Box>
                  );
                }
              } else {
                return (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "80vh" }}
                  >
                    <Typography variant="h4" color="initial">
                      Please connect to wallet on the{" "}
                      {getNetworkName(tokenData ? tokenData.chainId : 0)}
                    </Typography>
                  </Box>
                );
              }
            }
          })()}
        </Box>
        // <div style={{ marginTop: "2rem", color: "white" }}>
        // </div>
      )}
    </>
  );
};

export default MintToken;

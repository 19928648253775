import { IProviderOptions } from "web3modal";
import WalletConnectProvider from '@walletconnect/web3-provider'
import { WalletLink, WalletLinkProvider } from 'walletlink'

export const web3ModalProviderOptions: IProviderOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
  },
  "custom-walletlink": {
    display: {
      logo: "/images/coinbase.svg",
      name: "Coinbase",
      description: "Use Coinbase Wallet app on mobile device",
    },
    options: {
      appName: "Coinvise", // Your app name
      networkUrl: `https://mainnet.infura.io/v3/88085bc124ef4602ba0ff7d26bbaf2c5`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_, options): Promise<WalletLinkProvider> => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

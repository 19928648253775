import React, { useState, useEffect, FC } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import axios from "axios";
import Titlebar from "../Components/Titlebar";
import { Typography, Grid, LinearProgress } from "@material-ui/core";

import { getTransactionExplorerUrl, mintNft } from "../utils/ethereumUtils";

import { MetaMaskButton } from "rimble-ui";
import useSearchQuery from "../utils/useSearchQuery";


interface NftRequestInterface {
  requestId: string;
  guildId: string;
  channetId: string;
  fromDiscordId: string;
  toDiscordId: string;
  fullfilled: string;
  chainId: number;
  transactionId: string;
  metadataIpfsCid: string;
}

const MintNft: FC = (): JSX.Element => {
  const query = useSearchQuery()
  const nftRequestId = query.get("mintRequestId")
  const [transactionUrl, setTransactionUrl] = useState<string | undefined>();
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [mintNftRequest, setMintNftRequest] = useState<
    NftRequestInterface | undefined
  >(undefined);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { active, account, chainId } = useWeb3React();

  useEffect(() => {
    const f = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/mintNft/${nftRequestId}`
        );
        if (response.status === 200) {
          setMintNftRequest(response.data);

          // Get the image url
          const { image } = (
            await axios.get(
              `https://ipfs.io/ipfs/${response.data.metadataIpfsCid}`
            )
          ).data.properties;
          setImageUrl(image);

          setIsLoading(false);
        } else {
          setErrorMessage(response.data);
        }
      } catch (error) {
        setErrorMessage(error);
        console.error(error);
        setIsLoading(false);
      }
    };
    f().then(console.log).catch(console.error);
  }, [nftRequestId]);

  return (
    <>
      <Titlebar />
      {isLoading && <LinearProgress />}
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {errorOccured ? (
          <Grid container spacing={2} justify="center">
            <Grid item xs={6}>
              <Typography variant="h3" style={{ color: "white" }}>
                Sending Failed
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5" style={{ color: "white" }}>
                {errorMessage}
              </Typography>
            </Grid>
          </Grid>
        ) : transactionUrl ? (
          <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
              <Typography variant="h3" style={{ color: "white" }}>
                NFT Minted 🎉
              </Typography>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ color: "white" }}>
                  Transaction Url: <a href={transactionUrl}>Link</a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : active && mintNftRequest && imageUrl ? (
          chainId === mintNftRequest.chainId ? (
            <Grid container spacing={1} justify="center">
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  display: "block",
                  objectFit: "contain",
                }}
              >
                <img
                  src={imageUrl}
                  alt="nftImage"
                  style={{ maxWidth: "60%" }}
                />
              </Grid>
              <Grid container item spacing={1} xs={12} justify="center">
                <Grid item>
                  <Typography variant="h3" style={{ color: "white" }}>
                    Mint an NFT
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" style={{ color: "purple" }}>
                    from {account}
                  </Typography>
                </Grid>
                <Grid item>
                  <MetaMaskButton
                    onClick={async () => {
                      try {
                        const provider = new ethers.providers.Web3Provider(
                          window.ethereum
                        );
                        setIsLoading(true);
                        const txn = await mintNft(
                          account as string,
                          mintNftRequest.metadataIpfsCid,
                          provider.getSigner(),
                          mintNftRequest.chainId
                        );
                        const response = await axios.post(
                          `${process.env.REACT_APP_BACKEND_URL}/mintNft/${nftRequestId}/fullfill`,
                          { transactionHash: txn.hash }
                        );

                        setIsLoading(false);
                        console.log(response.status, response.statusText);
                        if (response.status === 200) {
                          setTransactionUrl(
                            getTransactionExplorerUrl(
                              txn,
                              mintNftRequest.chainId
                            )
                          );
                        }
                      } catch (error) {
                        console.error(error);
                        setErrorOccured(true);
                        setErrorMessage(error.toString());
                      }
                    }}
                    variant="contained"
                    size="large"
                  >
                    Mint using MetaMask
                  </MetaMaskButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h3" style={{ color: "purple" }}>
              Please set your metamask chain to {mintNftRequest.chainId}
            </Typography>
          )
        ) : (
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Typography variant="h3" style={{ color: "white" }}>
                Start by Connecting your Metamask Wallet
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{ color: "white" }}>
                Make sure your MetaMask Wallet is set to the correct chain
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default MintNft;

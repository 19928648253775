import { ethers, Transaction } from "ethers";
import decimal from "decimal.js";
import erc20abi from "../contracts/erc20abi";
import erc721abi from "../contracts/erc721tokenabi";

export const getChainIdFromChainName = (chain: "matic" | "mainnet"): number =>
  ({
    matic: parseInt(process.env.REACT_APP_MATIC_CHAIN_ID as string),
    mainnet: parseInt(process.env.REACT_APP_MAINNET_CHAIN_ID as string),
  }[chain]);

// const getJsonRpcProvider = (
//   chainId: number
// ): ethers.providers.JsonRpcProvider => {
//   const url = {
//     [parseInt(process.env.REACT_APP_MATIC_CHAIN_ID as string)]: process.env
//       .REACT_APP_MATIC_PROVIDER_URL_JSON_RPC,
//     [parseInt(process.env.REACT_APP_MAINNET_CHAIN_ID as string)]: process.env
//       .REACT_APP_MAINNET_PROVIDER_URL_JSON_RPC,
//   }[chainId];
//   return new ethers.providers.JsonRpcProvider(url);
// };

export const getWebsocketProvider = (
  chainId: number
): ethers.providers.WebSocketProvider =>
  new ethers.providers.WebSocketProvider(
    {
      [parseInt(process.env.REACT_APP_MATIC_CHAIN_ID as string)]: process.env
        .REACT_APP_MATIC_PROVIDER_URL_WEBSOCKET as string,
      [parseInt(process.env.REACT_APP_MAINNET_CHAIN_ID as string)]: process.env
        .REACT_APP_MAINNET_PROVIDER_URL_WEBSOCKET as string,
    }[chainId]
  );

export const getTransactionExplorerUrl = (
  transaction: Transaction,
  chainId: number
): string =>
  `${
    {
      [parseInt(process.env.REACT_APP_MATIC_CHAIN_ID as string)]:
        process.env.REACT_APP_MATIC_EXPLORER_URL,
      [parseInt(process.env.REACT_APP_MAINNET_CHAIN_ID as string)]:
        process.env.REACT_APP_MAINNET_EXPLORER_URL,
    }[chainId]
  }/tx/${transaction.hash}`;

const getNftTokenAddress = (chainId: number): string | undefined =>
  ({
    [parseInt(process.env.REACT_APP_MATIC_CHAIN_ID as string)]:
      process.env.REACT_APP_MATIC_NFT_CONTRACT,
    [parseInt(process.env.REACT_APP_MAINNET_CHAIN_ID as string)]:
      process.env.REACT_APP_MAINNET_NFT_CONTRACT,
  }[chainId]);

const getTokenBalance = async (
  tokenAddress: string,
  userAddress: string,
  chainId: number
): Promise<ethers.BigNumber> => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const contract = new ethers.Contract(tokenAddress, erc20abi, provider);
  return await contract.balanceOf(userAddress);
};

export const getTokenDecimals = async (
  tokenAddress: string,
  chainId: number
): Promise<ethers.BigNumber> => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const contract = new ethers.Contract(tokenAddress, erc20abi, provider);
  return await contract.decimals();
};

export const sendTokens = async (
  recieverAddress: string,
  tokenAddress: string,
  transferAmount: ethers.BigNumber,
  provider: ethers.providers.JsonRpcSigner
): Promise<Transaction> => {
  const contract = new ethers.Contract(tokenAddress, erc20abi, provider);
  return await contract.transfer(recieverAddress, transferAmount);
};

export const getTransferAmount = async (
  tokenAddress: string,
  amount: string,
  chainId: number
): Promise<ethers.BigNumber> => {
  // const provider = getJsonRpcProvider(chainId);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const contract = new ethers.Contract(tokenAddress, erc20abi, provider);

  // Calculate token balance
  const decimals = await contract.decimals();

  // Break down amount into integer and exponent part
  let amountConverted = new decimal(amount);
  let digitsAfterDecimal = 0;
  while (amountConverted.mod(1).greaterThan(0)) {
    digitsAfterDecimal++;
    amountConverted = amountConverted.mul(10);
  }

  return ethers.BigNumber.from(amountConverted.toString()).mul(
    ethers.BigNumber.from(10).pow(decimals - digitsAfterDecimal)
  );
};

export const mintNft = async (
  finalOwnerAddress: string,
  metadataCid: string,
  signer: ethers.providers.JsonRpcSigner,
  chainId: number
): Promise<Transaction> => {
  const contract = new ethers.Contract(
    getNftTokenAddress(chainId) as string,
    erc721abi,
    signer
  );

  console.log("address", getNftTokenAddress(chainId) as string);
  return await contract.mintTo(metadataCid, finalOwnerAddress);
};

export const isEth = (chainId: number): boolean => {
  if ([1, 3].includes(chainId)) {
    return true;
  } else if ([137, 80001].includes(chainId)) {
    return false;
  } else {
    return false;
  }
};

export const getNetworkName = (chainId: number): string => {
  switch (chainId) {
    case 1:
      return "Ethereum Mainnet";
    case 3:
      return "Ropsten Testnet";
    case 137:
      return "Matic (Polygon) Mainnet";
    case 80001:
      return "Mumbai Testnet";
    default:
      return "Unknown";
  }
};

export const getTxUrl = (chainId: number, txHash: string): string => {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/tx/${txHash}`;
    case 3:
      return `https://ropsten.etherscan.io/tx/${txHash}`;
    case 137:
      return `https://explorer-mainnet.maticvigil.com/tx/${txHash}`;
    case 80001:
      return `https://explorer-mumbai.maticvigil.com/tx/${txHash}`;
    default:
      return "";
  }
};

import { FC } from "react";
import { useWeb3React } from "@web3-react/core";
import { AppBar, Grid } from "@material-ui/core";
import { Injected } from "../utils/web3-connectors";
import { MetaMaskButton } from "rimble-ui";

const styles = {
  appbar: {
    background: "transparent",
    boxShadow: "none",
    backdropFilter: "blur(15px)",
    marginTop: 10,
  },
};

const Titlebar: FC = () => {
  const { account, activate, deactivate } = useWeb3React();
  return (
    <AppBar position="static" style={styles.appbar}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <img
            src="https://www.coinvise.co/images/logo-black.svg"
            alt="logo"
            height="64px"
            width="auto"
            style={{ paddingLeft: 72, paddingTop: 16 }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          spacing={2}
          alignItems="center"
          justify="flex-end"
        >
          <MetaMaskButton
            onClick={() => (account ? deactivate() : activate(Injected))}
            style={{
              marginRight: 72,
              marginTop: 16,
              backgroundColor: "white",
              color: "black",
            }}
          >
            {account ? "Disconnect" : "Connect to Metamask"}
          </MetaMaskButton>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Titlebar;

import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Mint Grotesk",
    h1: {
      fontFamily: "GT Flexa",
    },
    h2: {
      fontFamily: "GT Flexa",
    },
    h3: {
      fontFamily: "GT Flexa",
    },
    h4: {
      fontFamily: "GT Flexa",
    },
    h5: {
      fontFamily: "GT Flexa",
    },
    h6: {
      fontFamily: "GT Flexa",
    },
  },
});

export default theme;

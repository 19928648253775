import React, { FC, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import axios from "axios";
import Titlebar from "../Components/Titlebar";
import { Typography, Grid, LinearProgress } from "@material-ui/core";
import { MetaMaskButton } from "rimble-ui";
import useSearchQuery from "../utils/useSearchQuery";

const Register: FC = ()=> {
  const query = useSearchQuery();
  const registrationRequestId = query.get("registrationId");
  const [registered, setRegisterd] = useState(false);
  const [registeredError, setRegisteredError] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { active, account } = useWeb3React();
  return (
    <>
      <Titlebar />
      {isLoading && <LinearProgress />}
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {registeredError ? (
          <Grid container spacing={2} justify="center">
            <Grid item xs={6}>
              <Typography variant="h3" style={{ color: "white" }}>
                Registration Failed
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5" style={{ color: "white" }}>
                {registerErrorMessage}
              </Typography>
            </Grid>
          </Grid>
        ) : registered ? (
          <Typography variant="h3" style={{ color: "white" }}>
            Registration Complete 🎉
          </Typography>
        ) : active ? (
          <Grid container spacing={1} justify="center">
            <Grid item>
              <Typography variant="h3" style={{ color: "white" }}>
                Your Ethereum Address:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" style={{ color: "purple" }}>
                {account}
              </Typography>
            </Grid>
            <Grid item>
              <MetaMaskButton
                onClick={async () => {
                  const message = `${registrationRequestId}-${account}`;
                  const provider = new ethers.providers.Web3Provider(
                    window.ethereum
                  );
                  const signer = provider.getSigner();
                  console.log("Address: ", await signer.getAddress());
                  const signature = await signer.signMessage(message);
                  // Send the request
                  try {
                    setIsLoading(true);
                    const response = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/register/${registrationRequestId}`,
                      {
                        signature,
                        address: account,
                      }
                    );
                    setIsLoading(false);
                    console.log(response.status, response.statusText);
                    if (response.status === 200) {
                      setRegisterd(true);
                    }
                  } catch (error) {
                    console.error(error);
                    setRegisteredError(true);
                    setRegisterErrorMessage(error.toString());
                  }
                }}
                variant="contained"
                size="large"
              >
                Verify using MetaMask
              </MetaMaskButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Typography variant="h3" style={{ color: "white" }}>
                Start by Connecting your Metamask Wallet
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{ color: "white" }}>
                Make sure your MetaMask Wallet is set to Ethereum Mainnet
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default Register;

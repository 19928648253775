import { Grid, LinearProgress, Typography } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import { ethers } from "ethers";
import React, { FC, useEffect, useState } from "react";
import { MetaMaskButton } from "rimble-ui";
import {
  getTokenDecimals,
  getTransactionExplorerUrl,
  sendTokens,
} from "../utils/ethereumUtils";
import useSearchQuery from "../utils/useSearchQuery";
import Titlebar from "../Components/Titlebar";

interface TipInterface {
  requestId: string;
  guildId: string;
  fromDiscordId: string;
  toDiscordId: string;
  toAddress: string;
  tokenName: string;
  tokenAddress: string;
  fullfilled: string;
  chainId: number;
  tipAmount: string;
  transactionId: string;
}

const Tip: FC = () => {
  const query = useSearchQuery();
  const tipRequestId = query.get("tipRequestId");
  const [transactionUrl, setTransactionUrl] = useState<string | undefined>();
  const [contractDecimals, setContractDecimals] =
    useState<number | undefined>(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [tip, setTip] = useState<TipInterface | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { active, account, chainId } = useWeb3React();

  useEffect(() => {
    const f = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/tip/${tipRequestId}`
        );
        setIsLoading(false);
        if (response.status === 200) {
          const decimals = await getTokenDecimals(
            response.data.tokenAddress,
            response.data.chainId
          );
          setContractDecimals(parseInt(decimals.toString()));
          setTip(response.data);
        } else {
          setErrorMessage(response.data);
        }
      } catch (error) {
        setErrorMessage(error);
        console.error(error);
        setIsLoading(false);
      }
    };
    f().then(console.log).catch(console.error);
  }, [tipRequestId]);

  return (
    <>
      <Titlebar />
      {isLoading && <LinearProgress />}
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {errorOccured ? (
          <Grid container spacing={2} justify="center">
            <Grid item xs={6}>
              <Typography variant="h3" style={{ color: "white" }}>
                Sending Failed
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5" style={{ color: "white" }}>
                {errorMessage}
              </Typography>
            </Grid>
          </Grid>
        ) : transactionUrl ? (
          <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
              <Typography variant="h3" style={{ color: "white" }}>
                Tokens Sent 🎉.{" "}
              </Typography>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ color: "white" }}>
                  Transaction Url: <a href={transactionUrl}>Link</a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : active && tip && contractDecimals ? (
          chainId === tip.chainId ? (
            <Grid container spacing={1} justify="center">
              <Grid item>
                <Typography variant="h3" style={{ color: "white" }}>
                  Sending{" "}
                  {new BigNumber(tip.tipAmount)
                    .dividedBy(new BigNumber(10).pow(contractDecimals))
                    .toString()}{" "}
                  tokens to {tip.toAddress}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ color: "purple" }}>
                  from {account}
                </Typography>
              </Grid>
              <Grid item>
                <MetaMaskButton
                  onClick={async () => {
                    try {
                      const provider = new ethers.providers.Web3Provider(
                        window.ethereum
                      );
                      setIsLoading(true);
                      const txn = await sendTokens(
                        tip.toAddress,
                        tip.tokenAddress,
                        ethers.BigNumber.from(tip.tipAmount),
                        provider.getSigner()
                      );
                      const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/tip/${tipRequestId}/fullfill`,
                        { transactionHash: txn.hash }
                      );

                      setIsLoading(false);
                      console.log(response.status, response.statusText);
                      if (response.status === 200) {
                        setTransactionUrl(
                          getTransactionExplorerUrl(txn, tip.chainId)
                        );
                      }
                    } catch (error) {
                      console.error(error);
                      setErrorOccured(true);
                      setErrorMessage(error.toString());
                    }
                  }}
                  variant="contained"
                  size="large"
                >
                  Send using MetaMask
                </MetaMaskButton>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h3" style={{ color: "purple" }}>
              Please set your metamask chain to {tip.chainId}
            </Typography>
          )
        ) : (
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Typography variant="h3" style={{ color: "white" }}>
                Start by Connecting your Metamask Wallet
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{ color: "white" }}>
                Make sure your MetaMask Wallet is set to the correct chain
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default Tip;

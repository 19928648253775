import { Web3Provider } from "@ethersproject/providers";
import { ThemeProvider } from "@material-ui/core";
import { Web3ReactProvider } from "@web3-react/core";
import * as dotenv from "dotenv";
import { FC } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import "./App.css";
import "./assets/fonts/GT-Flexa/GT-Flexa-Bold-Italic-Trial.woff2";
import "./assets/fonts/GT-Flexa/GT-Flexa-Bold-Trial.woff2";
import "./assets/fonts/GT-Flexa/GT-Flexa-Medium-Italic-Trial.woff2";
import "./assets/fonts/GT-Flexa/GT-Flexa-Medium-Trial.woff2";
import "./assets/fonts/MintGrotesk/MintGroteskItalicTrial-Medium.otf";
import "./assets/fonts/MintGrotesk/MintGroteskItalicTrial-Regular.otf";
import "./assets/fonts/MintGrotesk/MintGroteskTrial-Bold.otf";
import "./assets/fonts/MintGrotesk/MintGroteskTrial-Medium.otf";
import "./assets/fonts/MintGrotesk/MintGroteskTrial-Regular.otf";
import MintNft from "./pages/MintNft";
import MintToken from "./pages/MintToken";
import Register from "./pages/Register";
import Tip from "./pages/Tip";
import "./styles/fonts.css";
import theme from "./styles/theme";
dotenv.config();

const App: FC = () => {
  return (
    <Web3ReactProvider
      getLibrary={(provider, connector) => new Web3Provider(provider)}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/register" component={Register} />
            <Route exact path="/mint-token" component={MintToken} />
            <Route exact path="/tip" component={Tip} />
            <Route exact path="/mintNft" component={MintNft} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Web3ReactProvider>
  );
};

export default App;
